import PersonIcon from '@material-ui/icons/Person';
import { UserCreate, UserEdit, UserList } from './users';

const index = {
  list: UserList,
  icon: PersonIcon,
  create: UserCreate,
  edit: UserEdit,
  options: { label: 'Usuarios' },
  name: "user",
};

export default index;
