import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { Grid } from '@mui/material';
import PhotoIcon from '@material-ui/icons/Photo';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import CropImage from './CropImage';

import { useInput } from 'react-admin';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  media: {
    height: 'auto',
    width: 'auto',
    padding: '6px',
  },
  input: {
    display: 'none',
  },
  title: {
    fontSize: 14,
  },
});

export default function ImageInput(props) {
  const name = props.name || props.source;
  const { input, meta: { touched, error }, isRequired } = useInput(props);
  const image = input.value;
  let label = props.label || 'imágen';
  if (isRequired) label += ' *';
  if (error === 'ra.validation.required') label += ' Requerido';
  const [cutting, setCutting] = useState(false);

  const classes = useStyles();

  const onChange = e => {
    const file = e.target.files[0];
    const srcFile = window.URL.createObjectURL(file);
    input.onChange(srcFile);
    if (props.aspect)
      setCutting(true);
  }

  const setCroppedImage = e => {
    if (props.aspect)
      setCutting(false);
    input.onChange(e);
  }

  return (
    <Grid container>
      <Grid items xs={12}>
        <Card className={classes.root}>
          {!cutting && <div className={classes.media}>
            <div style={{position: 'relative'}} >
              <img src={image ? image : 'image-upload.png'} />
              { image && 
                <RemoveCircleIcon titleAccess='Borrar imágen' style={{color: 'red', borderRadius: '20px', backgroundColor: 'white', cursor: 'pointer', position: 'absolute', top: '1%', left: '1%'}} onClick={() => input.onChange('')} />
              }
            </div>
          </div>}
        </Card>
      </Grid>
      <Grid item xs={6}>
        {cutting &&
          <div className={classes.media} style={{ marginBottom: '20px' }}>
            <CropImage
              zoom
              aspect={props.aspect}
              onCrop={img => setCroppedImage(img)}
              image={image}
            />
          </div>
        }
      </Grid>
      {!cutting &&
        <Grid items xs={12} style={{ marginTop: '10px' }}>
          <input
            name={name}
            id={name}
            accept="image/*"
            type="file"
            className={classes.input}
            onChange={onChange}
          />
          <label htmlFor={name}>
            {error === 'ra.validation.required' ?
              <Button style={{ marginBottom: '20px', backgroundColor: '#f5f5f5', color: '#f44336' }} size='small' variant='contained' component="span" startIcon={<PhotoIcon />}>
                {image ? `Cambiar ${label}` : `Agregar ${label}`}
              </Button>
              :
              <Button style={{ marginBottom: '20px', backgroundColor: '#f5f5f5' }} size='small' variant='contained' component="span" startIcon={<PhotoIcon />}>
                {image ? `Cambiar ${label}` : `Agregar ${label}`}
              </Button>
            }
          </label>
        </Grid>
      }
    </Grid>
  );
}