import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    Pagination,
    TextInput,
    DateField,
    ReferenceField,
    BulkDeleteButton
} from 'react-admin';
import { Fragment } from 'react';

const SessionPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

const CustomBulkActionButtons = ({ basePath }) => (
    <Fragment>
        <BulkDeleteButton label="Cerrar sesión" />
    </Fragment>
);


export const SessionList = props => {
    return (
        <List 
            {...props} 
            bulkActionButtons={<CustomBulkActionButtons />}
            pagination={<SessionPagination />} 
        >
            <Datagrid rowClick="edit">
    			<ReferenceField label="Usuario" source="id_user" reference="user" >
                    <TextField source="username" />
                </ReferenceField>
                <TextField label="IP" source="ip" />
                <TextField label="Dispositivo" source="agent" />
                <DateField label="Fecha" source="created_at" showTime />
                <DateField label="Cerrada" source="deleted_at" showTime />
            </Datagrid>
        </List>
    );
};

const SessionFilter = (props) => (
    <Filter {...props}>
		<TextInput label="Usuarios" source="user.username" alwaysOn />
    </Filter>
);
