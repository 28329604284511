import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { Grid } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Link from '@mui/material/Link';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { useInput } from 'react-admin';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  media: {
    height: 'auto',
    width: 'auto',
    padding: '6px',
  },
  input: {
    display: 'none',
  },
  title: {
    fontSize: 14,
  },
});

export default function FileInput(props) {
  const name = props.name || props.source;
  const { input, meta: { touched, error }, isRequired } = useInput(props);
  const file = input.value;
  let label = props.label || 'archivo';
  if (isRequired) label += ' *';
  if (error === 'ra.validation.required') label += ' Requerido';
  const classes = useStyles();

  const onChange = e => {
    console.log(e);
    const file = e.target.files[0];
    const srcFile = window.URL.createObjectURL(file);
    input.onChange(srcFile);
  }

  return (
    <Grid container>
      <Grid items xs={12}>
        <Card className={classes.root}>
          <div className={classes.media}>
            <div style={{position: 'relative'}} >
              <Link href={file || '#'} target="_blank" rel="noopener">
                <img src={'images.png'} />
              </Link>
              { file && 
                <RemoveCircleIcon titleAccess='Borrar imágen' style={{color: 'red', borderRadius: '20px', backgroundColor: 'white', cursor: 'pointer', position: 'absolute', top: '1%', left: '1%'}} onClick={() => input.onChange('')} />
              }
            </div>
          </div>
        </Card>
      </Grid>
      {file && <b>1 archivo seleccionado</b>}
      <Grid items xs={12} style={{ marginTop: '10px' }}>
        <input
          name={name}
          id={name}
          type="file"
          className={classes.input}
          onChange={onChange}
        />
        <label htmlFor={name}>
          {error === 'ra.validation.required' ?
            <Button style={{ marginBottom: '20px', backgroundColor: '#f5f5f5', color: '#f44336' }} size='small' variant='contained' component="span" startIcon={<UploadFileIcon />}>
              {file ? `Cambiar ${label}` : `Agregar ${label}`}
            </Button>
            :
            <Button style={{ marginBottom: '20px', backgroundColor: '#f5f5f5' }} size='small' variant='contained' component="span" startIcon={<UploadFileIcon />}>
              {file ? `Cambiar ${label}` : `Agregar ${label}`}
            </Button>
          }
        </label>
      </Grid>
    </Grid>
  );
}