import { NoticiaList, NoticiaCreate, NoticiaEdit } from './noticias';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const index = {
  list: NoticiaList,
  create: NoticiaCreate,
  edit: NoticiaEdit,
  icon: NewspaperIcon,
  options: { label: 'Noticias' },
  name: "noticia",
};

export default index;