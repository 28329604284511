import { MenuItemLink, Menu, DashboardMenuItem, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import DefaultIcon from '@material-ui/icons/ViewList';

export const CustomMenu = (props) => {
  const resources = useSelector(getResources);
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  return (
    <Menu {...props}>
      {resources.map(resource => ( resource.name !== 'familiarohermano' &&
        <MenuItemLink
          key={resource.name}
          to={resource.name === 'home' ? `/${resource.name}/create` : `/${resource.name}`}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          primaryText={resource.options.label || resource.name}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
    </Menu>
  );
};