import React from 'react';
import {
	List,
	TextInput,
	Datagrid,
	TextField,
	Filter,
	Pagination,
	Create,
	Edit,
	SelectInput,
	SimpleForm,
	PasswordInput,
	BulkDeleteButton,
	EmailField,
	required,
	ChipField,
	minLength
} from 'react-admin';
import { Fragment } from 'react';

const UserPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

const UserBulkActionButtons = props => (
	<Fragment>
		{/* default bulk delete action */}
		<BulkDeleteButton {...props} />
	</Fragment>
);

export const UserList = props => (
	<List
		{...props}
		bulkActionButtons={<UserBulkActionButtons />}
		pagination={<UserPagination />}
		filters={<UserFilter />}>
		<Datagrid rowClick="edit">
			<TextField label="Nombre" source="username" />
			<ChipField label="Rol" source="scope" size="small" />
		</Datagrid>
	</List>
);

export const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="/user">
			<TextInput validate={[required()]} label="Nombre de usuario" source="username" />
			{/* <TextInput label="Email" source="email" />
			<TextInput label="Phone" source="phone" /> */}
			<SelectInput validate={[required()]} label="Roles" source="scope" choices={TipoRol} defaultValue='admin' />
			<PasswordInput validate={[required(), minLength(6)]} label="Contraseña" source="password" autoComplete='new-password' />
		</SimpleForm>
	</Create>
);

export const UserEdit = props => (
	<Edit {...props}>
		<SimpleForm redirect="/user">
			<TextInput validate={[required()]} label="Nombre de usuario" source="username" />
			{/* <TextInput label="Email" source="email" />
			<TextInput label="Phone" source="phone" /> */}
			<SelectInput validate={[required()]} label="Roles" source="scope" choices={TipoRol} />
			<PasswordInput validate={[required(), minLength(6)]} label="Contraseña" source="password" autoComplete='new-password' />
		</SimpleForm>
	</Edit>
);

const UserFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Buscar" source="q" alwaysOn />
	</Filter>
);

const TipoRol = [
	{id: 'admin', name: 'admin'},
	{id: 'superadmin', name: 'superadmin'},
];