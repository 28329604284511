import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './CropWorker'
import { styles } from './styles'


const CropImage = (props) => {
  const image = props.image; 
  const classes = props.classes;
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      // fix black lines
      croppedAreaPixels.x++; 
      croppedAreaPixels.y++; 
      croppedAreaPixels.width -= 2;
      croppedAreaPixels.height -= 2;
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      props.onCrop(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  return (
    <div>
      <div className={classes.cropContainer}>
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={props.aspect || 4/3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.controls} style={{display: 'contents'}}>
        {props.zoom && <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.01}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>}
        { props.rotation && <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>}
        {props.onCrop && <Button
          onClick={showCroppedImage}
          variant="contained"
          component="span"
          size='small'
          style={{marginLeft: '0px'}}
          classes={{ root: classes.cropButton }}
        >
          Recortar
        </Button>}
      </div>
    </div>
  )
}

const StyledCropImage = withStyles(styles)(CropImage);
export default StyledCropImage;
