import jwt_decode from "jwt-decode";

const inMemoryJWTManager = () => {
  let logoutEventName = 'ra-logout';
  let refreshEndpoint = '/refresh';
  let inMemoryJWT = null;
  let refreshTimeOutId;
  let isRefreshing = null;

  // This listener allows to disconnect another session of react-admin started in another tab
  window.addEventListener('storage', (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  }

  const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

  // This countdown feature is used to renew the JWT in a way that is transparent to the user.
  // before it's no longer valid
  const refreshToken = (delay) => {
    if (inMemoryJWT) {
      refreshTimeOutId = window.setTimeout(
        getRefreshedToken,
        (delay - (jwt_decode(inMemoryJWT)).iat) * 1000 - 5000
      );
    }
  };

  const abordRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  // The method makes a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will return a fresh jwt.
  const getRefreshedToken = () => {
    const request = new Request(refreshEndpoint, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      // credentials: process.env.NODE_ENV === 'development' ? 'omit' : 'include',
    });
    isRefreshing = fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          ereaseToken();
          global.console.log(
            'Fallo la renovacion del token de seguridad'
          );
          return { token: null };
        }
        return response.json();
      })
      .then(({ access_token }) => {
        if (access_token) {
          let delay = jwt_decode(access_token);
          setToken(access_token, delay.exp);
          return true;
        }
        ereaseToken();
        return false;
      });
    return isRefreshing;

  };

  const getToken = () => {
    return inMemoryJWT;
  };

  const setToken = (token, delay) => {
    inMemoryJWT = token;
    inMemoryJWT = 'Bearer ' + token;
    refreshToken(delay);
    return true;
  };
  const ereaseToken = () => {
    inMemoryJWT = null;
    abordRefreshToken();
    window.localStorage.setItem(logoutEventName, Date.now());
    return true;
  }

  const setLogoutEventName = name => logoutEventName = name;

  return {
    ereaseToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
    waitForTokenRefresh,
    getRefreshedToken,
  }
};

export default inMemoryJWTManager();
