import httpClient from '../auth/httpClient';
import { fetchStart, fetchEnd } from 'react-admin';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const handleSubmitFiles = async ({ data, dispatch, notify, files }) => {
  const filesToUpload = files.filter(file => _.get(data, file)?.startsWith('blob'));
  if (filesToUpload.length < 1)
    return data;

  dispatch(fetchStart());
  
  const uploads = filesToUpload.map(fileToUpload => {
    return fetch(_.get(data, fileToUpload))
      .then(r => r.blob())
      .then(blob => {
        const formData = new FormData();
        formData.append('archivo', blob);
        return formData;
      }).then(formData => {
        return httpClient(`${process.env.REACT_APP_API_ENDPOINT}/api/archivo`, { method: 'POST', body: formData })
          .then(({ json }) => ({ name: fileToUpload, url: json.url }));
      })
  });

  return Promise.all(uploads)
    .then(files => {
      files.forEach(file => _.set(data, file.name, file.url));
      dispatch(fetchEnd());
      return data;
    })
    .catch(err => {
      console.log('err', err);
      notify(err?.message || 'Error al subir los archivos', { type: 'warning' });
      dispatch(fetchEnd());
      throw new Error;
    })
};

handleSubmitFiles.propTypes = {
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.string).isRequired
}